import { createSlice } from "@reduxjs/toolkit";

export const bannersSlice = createSlice({
  name: "banners",
  initialState: {
    getBannersResult: false,
    getBannersLoading: false,
    getBannersError: false,
    addBannersResult: false,
    addBannersLoading: false,
    deleteBannersResult: false,
  },
  reducers: {
    bannersReducers: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "GET_BANNERS":
          return {
            ...state,
            getBannersResult: payload.data,
            getBannersLoading: payload.loading,
            getBannersError: payload.error,
          };
        case "ADD_BANNERS":
          return {
            ...state,
            addBannersResult: payload.data,
            addBannersLoading: payload.loading,
          };
        case "DELETE_BANNERS":
          return {
            ...state,
            deleteBannersResult: payload.data,
          };
        default:
          return state;
      }
    },
  },
});

export const { bannersReducers } = bannersSlice.actions;

export default bannersSlice.reducer;
