import { postData, putData } from "actions";
import { CompInputs } from "components";
import { API_URL_pricelists, API_URL_pricelistgroups } from "constants";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pricelistsReducers } from "redux/pricelistsSlice";
import axios from "axios";

const AddPricelistItemPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState([
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Input Name",
      value: "",
    },
    {
      label: "Price",
      name: "price",
      type: "number",
      placeholder: "Input Price",
      value: "",
    },
    {
      label: "Pricelist Group",
      name: "pricelistgroup_pk",
      type: "select",
      placeholder: "Input Pricelist Group",
      value: "",
      options: [],
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      placeholder: "Input Status",
      value: "",
      options: [
        {
          value: "draft",
          label: "Draft",
        },
        {
          value: "published",
          label: "Published",
        },
      ],
    },
    {
      label: "Features",
      type: "group-input",
      addButton: true,
      groupInput: [
        [
          {
            label: "Feature",
            name: "feature",
            placeholder: "Input Feature",
            type: "text",
            value: "",
          },
        ],
      ],
    },
  ]);

  const handleInput = (e, inputIdx) => {
    const newInputs = [...inputs];
    newInputs[inputIdx].value = e.target.value;
    setInputs(newInputs);
  };

  const onSubmit = () => {
    const features = inputs[4].groupInput.map((feature) => feature[0].value);
    const data = {
      name: inputs[0].value,
      price: parseInt(inputs[1].value),
      pricelistgroup_pk: parseInt(inputs[2].value),
      status: inputs[3].value,
      features: features,
    };

    if (location.state === null) {
      postData(
        API_URL_pricelists,
        data,
        { dispatch, redux: pricelistsReducers },
        "ADD_PRICELISTS"
      );
      navigate("/pricelist/item");
    } else {
      putData(
        `${API_URL_pricelists}${location.state.item.id}`,
        data,
        { dispatch, redux: pricelistsReducers },
        "ADD_PRICELISTS"
      );
      navigate("/pricelist/item");
    }
  };

  useEffect(() => {
    const newInputs = [...inputs];
    axios({
      method: "GET",
      url: API_URL_pricelistgroups,
    }).then((response) => {
      newInputs[2].options = response.data.results.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setInputs(newInputs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (location.state !== null) {
      const newInputs = [...inputs];
      newInputs[0].value = location.state.item.name;
      newInputs[1].value = location.state.item.price;
      newInputs[2].value = location.state.item.pricelistgroup.id;
      newInputs[3].value = location.state.item.status;
      newInputs[4].groupInput = location.state.item.features.map((item) => [
        {
          label: "Feature",
          name: "feature",
          placeholder: "Input Feature",
          type: "text",
          value: item,
        },
      ]);
      setInputs(newInputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CompInputs
        inputs={inputs}
        setInputs={setInputs}
        handleInput={handleInput}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={onSubmit}
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddPricelistItemPage;
