import { postData, putData } from "actions";
import { CompInputs } from "components";
import { API_URL_pricelistgroups } from "constants";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pricelistGroupsReducers } from "redux/pricelistsSlice";

const AddPricelistGroupPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState([
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Input Name",
      value: "",
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      placeholder: "Input Status",
      value: "",
      options: [
        {
          value: "draft",
          label: "Draft",
        },
        {
          value: "published",
          label: "Published",
        },
      ],
    },
  ]);

  const handleInput = (e, inputIdx) => {
    const newInputs = [...inputs];
    newInputs[inputIdx].value = e.target.value;
    setInputs(newInputs);
  };

  const onSubmit = () => {
    const data = {
      name: inputs[0].value,
      status: inputs[1].value,
    };

    if (location.state === null) {
      postData(
        API_URL_pricelistgroups,
        data,
        { dispatch, redux: pricelistGroupsReducers },
        "ADD_PRICELISTGROUPS"
      );
      navigate("/pricelist/group");
    } else {
      putData(
        `${API_URL_pricelistgroups}${location.state.item.id}`,
        data,
        { dispatch, redux: pricelistGroupsReducers },
        "ADD_PRICELISTGROUPS"
      );
      navigate("/pricelist/group");
    }
  };

  useEffect(() => {
    if (location.state !== null) {
      const newInputs = [...inputs];
      newInputs[0].value = location.state.item.name;
      newInputs[1].value = location.state.item.status;
      setInputs(newInputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      <CompInputs
        inputs={inputs}
        setInputs={setInputs}
        handleInput={handleInput}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={onSubmit}
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddPricelistGroupPage;
