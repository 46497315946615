import { CompCardContainer } from "components";
import React, { useCallback, useEffect } from "react";
import { icons } from "assets/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteData, getData } from "actions";
import { API_URL_pricelists } from "constants";
import { pricelistsReducers } from "redux/pricelistsSlice";
import { SyncLoader } from "react-spinners";

const PricelistItemPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getPricelistsResult,
    getPricelistsLoading,
    getPricelistsError,
    addPricelistsResult,
    deletePricelistsResult,
  } = useSelector((state) => state.pricelists);

  const get = useCallback(
    async (params) => {
      getData(
        API_URL_pricelists,
        params,
        { dispatch, redux: pricelistsReducers },
        "GET_PRICELISTS"
      );
    },
    [dispatch]
  );

  const onEdit = (item) => {
    navigate(`/pricelist/item/form`, {
      state: {
        item,
      },
    });
  };

  const onDelete = (pk) => {
    deleteData(
      API_URL_pricelists + pk,
      { dispatch, redux: pricelistsReducers },
      "DELETE_PRICELISTS"
    );
  };

  const onSearch = (value) => {
    const params = `?limit=${""}&offset=${""}&search=${value}`;
    get(params);
  };

  const fetchData = useCallback(() => {
    const params = `?limit=${""}&offset=${""}&search=${""}`;
    get(params);
  }, [get]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (addPricelistsResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPricelistsResult]);
  useEffect(() => {
    if (deletePricelistsResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePricelistsResult]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-lg md:text-3xl font-bold transition-all">
          Pricelist Item
        </h1>
        <button
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
          onClick={() => navigate("/pricelist/item/form")}
        >
          Add Pricelist Item
        </button>
      </div>
      <br />
      <CompCardContainer>
        <div className="w-full flex text-gray-600">
          <div className="p-1 text-lg mr-3">{icons.fisearch}</div>
          <input
            type="text"
            className="w-full"
            placeholder="Search Pricelist Item"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </CompCardContainer>
      <br />

      {/* Content */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Loading */}
        {getPricelistsLoading && (
          <div className="w-full  h-56 col-span-full flex justify-center items-center">
            <SyncLoader
              loading={getPricelistsLoading}
              color="#1F2937"
              size={20}
            />
          </div>
        )}

        {/* Error */}
        {getPricelistsError && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm text-red-500 font-medium">
            {getPricelistsError}
          </div>
        )}

        {/* Result = 0 */}
        {getPricelistsResult && getPricelistsResult.count === 0 && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm font-medium">
            No Data
          </div>
        )}

        {getPricelistsResult &&
          getPricelistsResult.results.map((price, priceIdx) => (
            <div
              key={priceIdx}
              className="bg-white group relative w-full h-56 rounded-lg shadow p-4 flex flex-col justify-between hover:shadow-lg transition-all"
            >
              <div>
                <div className="text-xl font-medium mb-1">{price.name}</div>
                <div className="text-xl font-medium mb-1">
                  {price.price.toLocaleString("id", {
                    style: "currency",
                    currency: "IDR",
                    maximumFractionDigits: 0,
                  })}
                </div>
                <ul className="text-xs mt-4">
                  {price.features.slice(0, 3).map((item, itemIdx) => (
                    <li key={itemIdx} className="pb-1 line-clamp-1">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="text-[10px]">
                <div className="text-right">{price.pricelistgroup.name}</div>
                <div className="flex justify-between items-end">
                  <div>
                    {moment(price.updated_at).format("D MMMM YYYY, h:mm")}
                  </div>
                  <div>
                    <div>{price.status}</div>
                  </div>
                </div>
              </div>
              <div className="absolute flex gap-2 right-4 opacity-0 group-hover:opacity-100 transition-all">
                <button className="text-blue-600" onClick={() => onEdit(price)}>
                  {icons.fiedit}
                </button>
                <button
                  className="text-red-600"
                  onClick={() => onDelete(price.id)}
                >
                  {icons.rideletebin6line}
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PricelistItemPage;
