import React, { useState, Fragment, useCallback, useEffect } from "react";
import { CompCardContainer, CompPagination } from "components";
import { icons } from "assets/icons";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteData, getData } from "actions";
import { API_URL_articles } from "constants";
import { articlesReducers } from "redux/articlesSlice";
import moment from "moment";

const ArticlesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableHead = ["No", "Title", "Author", "Status", "Created at", "Action"];
  const {
    getArticlesResult,
    getArticlesLoading,
    getArticlesError,
    addArticlesResult,
    deleteArticlesResult,
  } = useSelector((state) => state.articles);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");

  const get = useCallback(
    async (params) => {
      getData(
        API_URL_articles,
        params,
        { dispatch, redux: articlesReducers },
        "GET_ARTICLES"
      );
    },
    [dispatch]
  );

  const onDelete = (item) => {
    deleteData(
      API_URL_articles + item.slug,
      { dispatch, redux: articlesReducers },
      "DELETE_ARTICLES"
    );
  };

  const onEdit = (item) => {
    navigate(`/articles/form`, {
      state: {
        item,
      },
    });
  };

  const onSearch = (value) => {
    setSearch(value);
    const params = `?limit=${""}&offset=${""}&search=${value}`;
    get(params);
  };

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const params = `?limit=${limit}&offset=${offset}&search=${search}`;
    get(params);
    setOffset(offset);
  };

  const handleSelect = (limit) => {
    const params = `?limit=${limit}&offset=${offset}&search=${search}`;
    get(params);
    setLimit(limit);
  };

  const action = [
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "hapus",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: onDelete,
    },
  ];

  const fetchData = useCallback(() => {
    const params = `?limit=${""}&offset=${""}&search=${""}`;
    get(params);
  }, [get]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (addArticlesResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addArticlesResult]);
  useEffect(() => {
    if (deleteArticlesResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteArticlesResult]);

  return (
    <Fragment>
      <div className="flex justify-between items-center">
        <h1 className="text-lg md:text-3xl font-bold transition-all">
          Articles
        </h1>
        <button
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
          onClick={() => navigate("/articles/form")}
        >
          Add Articles
        </button>
      </div>
      <br />
      <CompCardContainer>
        <div className="w-full flex text-gray-600">
          <div className="p-1 text-lg mr-3">{icons.fisearch}</div>
          <input
            type="text"
            className="w-full"
            placeholder="Search Articles"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </CompCardContainer>
      <br />

      {/* Content */}
      <CompCardContainer>
        <div className="overflow-y-auto custom-scroll">
          <table className="w-full">
            <thead>
              <tr className="border-b-2 border-gray-200">
                {tableHead.map((item, itemIdx) => (
                  <th key={itemIdx} className="p-2 text-sm whitespace-nowrap">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Loading */}
              {getArticlesLoading && (
                <tr>
                  <td
                    className="text-center py-12"
                    colSpan={tableHead.length + 1}
                  >
                    <div className="pt-10 pb-6 flex justify-center items-center">
                      <SyncLoader color="#111827" />
                    </div>
                  </td>
                </tr>
              )}

              {/* Error */}
              {getArticlesError && (
                <tr>
                  <td className="text-center" colSpan={tableHead.length + 1}>
                    <div className="pt-20 pb-12 flex justify-center items-center text-xs text-red-500">
                      {getArticlesError}
                    </div>
                  </td>
                </tr>
              )}

              {/* Result = 0 */}
              {getArticlesResult && getArticlesResult.results.length === 0 && (
                <tr>
                  <td className="text-center" colSpan={tableHead.length + 1}>
                    <div className="pt-20 pb-12 flex justify-center items-center text-xs text-slate-600">
                      No Data
                    </div>
                  </td>
                </tr>
              )}

              {getArticlesResult &&
                getArticlesResult.results.map((item, itemIdx) => (
                  <tr
                    key={itemIdx}
                    className="border-b border-gray-200 text-sm hover:bg-white/60 transition-all"
                  >
                    <td className="p-2 text-center whitespace-nowrap">
                      {itemIdx + offset + 1}
                    </td>
                    <td className="p-2 text-center">{item.title}</td>
                    <td className="p-2 text-center whitespace-nowrap">
                      {item.author.first_name + " " + item.author.last_name}
                    </td>
                    <td className="p-2 text-center whitespace-nowrap">
                      {item.status}
                    </td>
                    <td className="p-2 text-center whitespace-nowrap">
                      {moment(item.created_at).format("D MMMM YYYY")}
                    </td>
                    <td className="p-2 text-center whitespace-nowrap">
                      {action.map((action, actionIdx) => (
                        <button
                          key={actionIdx}
                          className={`mx-1 ${action.color}`}
                          onClick={() => action.func(item)}
                        >
                          {action.icon}
                        </button>
                      ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getArticlesResult.count > 0 ? getArticlesResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer>
    </Fragment>
  );
};

export default ArticlesPage;
