import { createSlice } from "@reduxjs/toolkit";

export const pricelistsSlice = createSlice({
  name: "pricelists",
  initialState: {
    // pricelistItems
    getPricelistsResult: false,
    getPricelistsLoading: false,
    getPricelistsError: false,
    addPricelistsResult: false,
    addPricelistsLoading: false,
    deletePricelistsResult: false,

    // pricelistGroups
    getPricelistGroupsResult: false,
    getPricelistGroupsLoading: false,
    getPricelistGroupsError: false,
    addPricelistGroupsResult: false,
    addPricelistGroupsLoading: false,
    deletePricelistGroupsResult: false,
  },
  reducers: {
    pricelistsReducers: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "GET_PRICELISTS":
          return {
            ...state,
            getPricelistsResult: payload.data,
            getPricelistsLoading: payload.loading,
            getPricelistsError: payload.error,
          };
        case "ADD_PRICELISTS":
          return {
            ...state,
            addPricelistsResult: payload.data,
            addPricelistsLoading: payload.loading,
          };
        case "DELETE_PRICELISTS":
          return {
            ...state,
            deletePricelistsResult: payload.data,
          };
        default:
          return state;
      }
    },
    pricelistGroupsReducers: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "GET_PRICELISTGROUPS":
          return {
            ...state,
            getPricelistGroupsResult: payload.data,
            getPricelistGroupsLoading: payload.loading,
            getPricelistGroupsError: payload.error,
          };
        case "ADD_PRICELISTGROUPS":
          return {
            ...state,
            addPricelistGroupsResult: payload.data,
            addPricelistGroupsLoading: payload.loading,
          };
        case "DELETE_PRICELISTGROUPS":
          return {
            ...state,
            deletePricelistGroupsResult: payload.data,
          };
        default:
          return state;
      }
    },
  },
});

export const { pricelistsReducers, pricelistGroupsReducers } =
  pricelistsSlice.actions;

export default pricelistsSlice.reducer;
