import { CompLayout } from "components";
import {
  AddArticlePage,
  AddBannerPage,
  AddPartnershipPage,
  AddPricelistGroupPage,
  AddPricelistItemPage,
  AddServicePage,
  ArticlesPage,
  BannerPage,
  DashboardPage,
  LoginPage,
  PartnershipPage,
  PricelistGroupPage,
  PricelistItemPage,
  PrivateRoute,
  ServicePage,
} from "pages";
import React from "react";
import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route element={<CompLayout />}>
              <Route index element={<DashboardPage />} />
              <Route path="service" element={<ServicePage />} />
              <Route path="service/form" element={<AddServicePage />} />
              <Route path="partnership" element={<PartnershipPage />} />
              <Route path="partnership/form" element={<AddPartnershipPage />} />
              <Route path="articles" element={<ArticlesPage />} />
              <Route path="articles/form" element={<AddArticlePage />} />
              <Route path="pricelist/group" element={<PricelistGroupPage />} />
              <Route
                path="pricelist/group/form"
                element={<AddPricelistGroupPage />}
              />
              <Route path="pricelist/item" element={<PricelistItemPage />} />
              <Route
                path="pricelist/item/form"
                element={<AddPricelistItemPage />}
              />
              <Route path="banner" element={<BannerPage />} />
              <Route path="banner/form" element={<AddBannerPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
