import { CompCardContainer } from "components";
import React, { useCallback, useEffect } from "react";
import { icons } from "assets/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteData, getData } from "actions";
import { API_URL_pricelistgroups } from "./../../constants/index";
import { pricelistGroupsReducers } from "redux/pricelistsSlice";
import moment from "moment";
import { SyncLoader } from "react-spinners";

const PricelistGroupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getPricelistGroupsResult,
    getPricelistGroupsLoading,
    getPricelistGroupsError,
    addPricelistGroupsResult,
    deletePricelistGroupsResult,
  } = useSelector((state) => state.pricelists);

  const get = useCallback(
    async (params) => {
      getData(
        API_URL_pricelistgroups,
        params,
        { dispatch, redux: pricelistGroupsReducers },
        "GET_PRICELISTGROUPS"
      );
    },
    [dispatch]
  );

  const onEdit = (item) => {
    navigate(`/pricelist/group/form`, {
      state: {
        item,
      },
    });
  };

  const onDelete = (pk) => {
    deleteData(
      API_URL_pricelistgroups + pk,
      { dispatch, redux: pricelistGroupsReducers },
      "DELETE_PRICELISTGROUPS"
    );
  };

  const onSearch = (value) => {
    const params = `?limit=${""}&offset=${""}&search=${value}`;
    get(params);
  };

  const fetchData = useCallback(() => {
    const params = `?limit=${""}&offset=${""}&search=${""}`;
    get(params);
  }, [get]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (addPricelistGroupsResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPricelistGroupsResult]);
  useEffect(() => {
    if (deletePricelistGroupsResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePricelistGroupsResult]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-lg md:text-3xl font-bold transition-all">
          Pricelist Group
        </h1>
        <button
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
          onClick={() => navigate("/pricelist/group/form")}
        >
          Add Pricelist Group
        </button>
      </div>
      <br />
      <CompCardContainer>
        <div className="w-full flex text-gray-600">
          <div className="p-1 text-lg mr-3">{icons.fisearch}</div>
          <input
            type="text"
            className="w-full"
            placeholder="Search Pricelist Group"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </CompCardContainer>
      <br />

      {/* Content */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Loading */}
        {getPricelistGroupsLoading && (
          <div className="w-full  h-56 col-span-full flex justify-center items-center">
            <SyncLoader
              loading={getPricelistGroupsLoading}
              color="#1F2937"
              size={20}
            />
          </div>
        )}

        {/* Error */}
        {getPricelistGroupsError && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm text-red-500 font-medium">
            {getPricelistGroupsError}
          </div>
        )}

        {/* Result = 0 */}
        {getPricelistGroupsResult && getPricelistGroupsResult.count === 0 && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm font-medium">
            No Data
          </div>
        )}

        {getPricelistGroupsResult &&
          getPricelistGroupsResult.results.map((price, priceIdx) => (
            <div
              key={priceIdx}
              className="bg-white group relative w-full h-56 rounded-lg shadow p-4 flex flex-col justify-between hover:shadow-lg transition-all"
            >
              <div>
                <div className="text-2xl font-medium mb-1">{price.name}</div>
                <ul className="text-xs mt-4">
                  {price.pricelist.slice(0, 4).map((item, itemIdx) => (
                    <li key={itemIdx} className="pb-1 line-clamp-1">
                      {item.name}{" "}
                      {`(${item.price.toLocaleString("id", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      })})`}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-between">
                <div className="text-[10px]">
                  {moment(price.updated_at).format("D MMMM YYYY, h:mm")}
                </div>
                <div className="text-[10px]">{price.status}</div>
              </div>
              <div className="absolute flex gap-2 right-4 opacity-0 group-hover:opacity-100 transition-all">
                <button className="text-blue-600" onClick={() => onEdit(price)}>
                  {icons.fiedit}
                </button>
                <button
                  className="text-red-600"
                  onClick={() => onDelete(price.id)}
                >
                  {icons.rideletebin6line}
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PricelistGroupPage;
