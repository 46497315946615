import { createSlice } from "@reduxjs/toolkit";

export const partnershipsSlice = createSlice({
  name: "partnerships",
  initialState: {
    getPartnershipsResult: false,
    getPartnershipsLoading: false,
    getPartnershipsError: false,
    addPartnershipsResult: false,
    addPartnershipsLoading: false,
    deletePartnershipsResult: false,
  },
  reducers: {
    partnershipsReducers: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "GET_PARTNERSHIPS":
          return {
            ...state,
            getPartnershipsResult: payload.data,
            getPartnershipsLoading: payload.loading,
            getPartnershipsError: payload.error,
          };
        case "ADD_PARTNERSHIPS":
          return {
            ...state,
            addPartnershipsResult: payload.data,
            addPartnershipsLoading: payload.loading,
          };
        case "DELETE_PARTNERSHIPS":
          return {
            ...state,
            deletePartnershipsResult: payload.data,
          };
        default:
          return state;
      }
    },
  },
});

export const { partnershipsReducers } = partnershipsSlice.actions;

export default partnershipsSlice.reducer;
