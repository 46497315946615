const host = "https://api.admin.qnn.co.id";
const api = "";

export const baseurl = `${host}${api}`;
export const API_URL_login = `${baseurl}/token/`;
export const API_URL_refresh = `${baseurl}/token/refresh/`;

export const API_URL_services = `${baseurl}/services/`;
export const API_URL_pricelistgroups = `${baseurl}/pricelistgroups/`;
export const API_URL_pricelists = `${baseurl}/pricelists/`;
export const API_URL_partnerships = `${baseurl}/partnerships/`;
export const API_URL_articles = `${baseurl}/articles/`;
export const API_URL_banners = `${baseurl}/banners/`;
