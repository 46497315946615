import { CompInputs } from "components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postData, putData } from "actions";
import { API_URL_banners } from "constants";
import { useDispatch } from "react-redux";
import { bannersReducers } from "redux/bannersSlice";

const AddBannerPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState([
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Input Name",
      value: "",
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      placeholder: "Input Status",
      value: "",
      options: [
        {
          value: "draft",
          label: "Draft",
        },
        {
          value: "published",
          label: "Published",
        },
      ],
    },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      placeholder: "Input Description",
      value: "",
    },
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
      placeholder: "Input Date",
      value: "",
      min: "",
      max: "",
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
      placeholder: "Input Date",
      value: "",
      min: "",
      max: "",
    },
    {
      label: "Image",
      name: "image",
      type: "file",
      placeholder: "Input Image",
      value: "",
      image: "",
      accept: "image/jpg, image/jpeg, image/png",
    },
  ]);
  const [imgPreview, setImgPreview] = useState("");

  const handleInput = (e, inputIdx) => {
    const newInputs = [...inputs];
    if (e.target.type === "file") {
      newInputs[inputIdx]["value"] = e.target.value;
      newInputs[inputIdx]["image"] = e.target.files[0];
      if (e.target.files[0]) {
        setImgPreview(URL.createObjectURL(e.target.files[0]));
      } else {
        setImgPreview("");
      }
    }
    if (e.target.type === "date") {
      newInputs[inputIdx]["value"] = e.target.value;
      if (inputIdx === 3) {
        newInputs[4]["min"] = e.target.value;
      }
      if (inputIdx === 4) {
        newInputs[3]["max"] = e.target.value;
      }
    }

    newInputs[inputIdx].value = e.target.value;
    setInputs(newInputs);
  };

  const onSubmit = () => {
    const formData = new FormData();

    formData.append("name", inputs[0].value);
    formData.append("status", inputs[1].value);
    formData.append("description", inputs[2].value);
    formData.append("start_date", inputs[3].value);
    formData.append("end_date", inputs[4].value);
    formData.append("image", inputs[5].image);

    if (location.state === null) {
      postData(
        API_URL_banners,
        formData,
        { dispatch, redux: bannersReducers },
        "ADD_BANNERS"
      );
      navigate("/banner");
    } else {
      putData(
        `${API_URL_banners}${location.state.item.id}`,
        formData,
        { dispatch, redux: bannersReducers },
        "ADD_BANNERS"
      );
      navigate("/banner");
    }
  };

  useEffect(() => {
    if (location.state !== null) {
      const newInputs = [...inputs];
      newInputs[0].value = location.state.item.name;
      newInputs[1].value = location.state.item.status;
      newInputs[2].value = location.state.item.description;
      newInputs[3].value = location.state.item.start_date;
      newInputs[4].value = location.state.item.end_date;
      setImgPreview(location.state.item.image);

      const fileName = location.state.item.image.split("/").pop();
      fetch(location.state.item.image).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: contentType });
        newInputs[5].image = file;
      });

      setInputs(newInputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      <CompInputs
        inputs={inputs}
        setInputs={setInputs}
        handleInput={handleInput}
      />
      <div className="font-medium">Image Preview</div>
      <div className="bg-white w-full shadow p-3 rounded-lg mb-3 mt-1 file:border-none file:px-3 file:py-1 file:rounded-lg text-sm">
        {imgPreview ? (
          <img
            className={`w-full h-96 object-cover rounded-lg`}
            src={imgPreview}
            alt="preview"
          />
        ) : (
          <div className="text-center">No Image</div>
        )}
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={onSubmit}
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddBannerPage;
