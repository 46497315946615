import { createSlice } from "@reduxjs/toolkit";

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    getServicesResult: false,
    getServicesLoading: false,
    getServicesError: false,
    addServicesResult: false,
    addServicesLoading: false,
    deleteServicesResult: false,
  },
  reducers: {
    servicesReducers: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "GET_SERVICES":
          return {
            ...state,
            getServicesResult: payload.data,
            getServicesLoading: payload.loading,
            getServicesError: payload.error,
          };
        case "ADD_SERVICES":
          return {
            ...state,
            addServicesResult: payload.data,
            addServicesLoading: payload.loading,
          };
        case "DELETE_SERVICES":
          return {
            ...state,
            deleteServicesResult: payload.data,
          };
        default:
          return state;
      }
    },
  },
});

export const { servicesReducers } = servicesSlice.actions;

export default servicesSlice.reducer;
