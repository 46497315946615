import React, { useCallback, useEffect, Fragment } from "react";
import { icons } from "assets/icons";
import { CompCardContainer } from "components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { deleteData, getData } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { partnershipsReducers } from "redux/partnershipsSlice";
import { API_URL_partnerships } from "constants";
import { SyncLoader } from "react-spinners";

const PartnershipPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getPartnershipsResult,
    getPartnershipsLoading,
    getPartnershipsError,
    addPartnershipsResult,
    deletePartnershipsResult,
  } = useSelector((state) => state.partnerships);

  const get = useCallback(
    async (params) => {
      getData(
        API_URL_partnerships,
        params,
        { dispatch, redux: partnershipsReducers },
        "GET_PARTNERSHIPS"
      );
    },
    [dispatch]
  );

  const onEdit = (item) => {
    navigate(`/partnership/form`, {
      state: {
        item,
      },
    });
  };

  const onDelete = (pk) => {
    deleteData(
      API_URL_partnerships + pk,
      { dispatch, redux: partnershipsReducers },
      "DELETE_PARTNERSHIPS"
    );
  };

  const onSearch = (value) => {
    const params = `?limit=${""}&offset=${""}&search=${value}`;
    get(params);
  };

  const fetchData = useCallback(() => {
    const params = `?limit=${""}&offset=${""}&search=${""}`;
    get(params);
  }, [get]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (addPartnershipsResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPartnershipsResult]);
  useEffect(() => {
    if (deletePartnershipsResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePartnershipsResult]);

  return (
    <Fragment>
      <div className="flex justify-between items-center">
        <h1 className="text-lg md:text-3xl font-bold transition-all">
          Partnership
        </h1>
        <button
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
          onClick={() => navigate("/partnership/form")}
        >
          Add Partnership
        </button>
      </div>
      <br />
      <CompCardContainer>
        <div className="w-full flex text-gray-600">
          <div className="p-1 text-lg mr-3">{icons.fisearch}</div>
          <input
            type="text"
            className="w-full"
            placeholder="Search Partnership"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </CompCardContainer>
      <br />

      {/* Content */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Loading */}
        {getPartnershipsLoading && (
          <div className="w-full  h-56 col-span-full flex justify-center items-center">
            <SyncLoader
              loading={getPartnershipsLoading}
              color="#1F2937"
              size={20}
            />
          </div>
        )}

        {/* Error */}
        {getPartnershipsError && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm text-red-500 font-medium">
            {getPartnershipsError}
          </div>
        )}

        {/* Result = 0 */}
        {getPartnershipsResult && getPartnershipsResult.count === 0 && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm font-medium">
            No Data
          </div>
        )}

        {/* Result */}
        {getPartnershipsResult &&
          getPartnershipsResult.results.map((partner, partnerIdx) => (
            <div
              key={partnerIdx}
              className="bg-white group relative w-full h-56 rounded-lg shadow p-4 flex flex-col justify-between hover:shadow-lg transition-all"
            >
              <div className="mt-7">
                <img
                  src={partner.image}
                  alt={partner.name}
                  className="w-full h-28 object-cover"
                />
                <div className="font-medium text-sm mt-1">{partner.name}</div>
              </div>
              <div className="flex justify-between">
                <div className="text-[10px]">
                  {moment(partner.updated_at).format("D MMMM YYYY, h:mm")}
                </div>
                <div className="text-[10px]">{partner.status}</div>
              </div>
              <div className="absolute flex gap-2 right-4 opacity-0 group-hover:opacity-100 transition-all">
                <button
                  className="text-blue-600"
                  onClick={() => onEdit(partner)}
                >
                  {icons.fiedit}
                </button>
                <button
                  className="text-red-600"
                  onClick={() => onDelete(partner.id)}
                >
                  {icons.rideletebin6line}
                </button>
              </div>
            </div>
          ))}
      </div>
    </Fragment>
  );
};

export default PartnershipPage;
