import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { CompInputs } from "components";
import { useState } from "react";
import slugify from "slugify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postData, putData } from "actions";
import { API_URL_articles } from "constants";
import { articlesReducers } from "redux/articlesSlice";

const AddArticlePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const initEditor = {
    plugins:
      "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
    imagetools_cors_hosts: ["picsum.photos"],
    menubar: "file edit view insert format tools table help",
    toolbar:
      "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
    toolbar_sticky: true,
    autosave_prefix: "{path}{query}-{id}-",
    image_advtab: true,
    importcss_append: true,
    height: 520,
    image_caption: true,
    quickbars_selection_toolbar:
      "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
    toolbar_mode: "sliding",
    contextmenu: "link image imagetools table",
  };
  const [inputs, setInputs] = useState([
    {
      label: "Title",
      name: "title",
      type: "text",
      placeholder: "Input Title",
      value: "",
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      placeholder: "Input Status",
      value: "",
      options: [
        {
          value: "draft",
          label: "Draft",
        },
        {
          value: "published",
          label: "Published",
        },
      ],
    },
    {
      label: "Image",
      name: "image",
      type: "file",
      placeholder: "Input Image",
      value: "",
      image: "",
      accept: "image/*",
    },
  ]);
  const [content, setContent] = useState();
  const [imgPreview, setImgPreview] = useState("");

  const handleInput = (e, inputIdx) => {
    const newInputs = [...inputs];
    if (e.target.type === "file") {
      newInputs[inputIdx]["value"] = e.target.value;
      newInputs[inputIdx]["image"] = e.target.files[0];
      if (e.target.files[0]) {
        setImgPreview(URL.createObjectURL(e.target.files[0]));
      } else {
        setImgPreview("");
      }
    }
    newInputs[inputIdx].value = e.target.value;
    setInputs(newInputs);
  };

  const onSubmit = () => {
    const formData = new FormData();

    formData.append("title", inputs[0].value);
    formData.append("status", inputs[1].value);
    if (inputs[0].value !== "") {
      formData.append(
        "slug",
        slugify(inputs[0].value, { lower: true, strict: true })
      );
    }
    if (editorRef.current && editorRef.current.getContent() !== "") {
      formData.append("content", editorRef.current.getContent());
    }
    if (inputs[2].image) {
      formData.append("image", inputs[2].image);
    }
    formData.append("author_pk", 1);

    if (location.state === null) {
      postData(
        API_URL_articles,
        formData,
        { dispatch, redux: articlesReducers },
        "ADD_ARTICLES"
      );
      navigate("/articles");
    } else {
      putData(
        `${API_URL_articles}${location.state.item.slug}/`,
        formData,
        { dispatch, redux: articlesReducers },
        "ADD_ARTICLES"
      );
      navigate("/articles");
    }
  };

  useEffect(() => {
    if (location.state !== null) {
      const newInputs = [...inputs];
      newInputs[0].value = location.state.item.title;
      newInputs[1].value = location.state.item.status;
      setContent(location.state.item.content);
      setImgPreview(location.state.item.image);

      const fileName = location.state.item.image.split("/").pop();
      fetch(location.state.item.image).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: contentType });
        newInputs[2].image = file;
      });

      setInputs(newInputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      <CompInputs
        inputs={inputs}
        setInputs={setInputs}
        handleInput={handleInput}
      />
      <div className="font-medium">Image Preview</div>
      <div className="bg-white w-full shadow p-3 rounded-lg mb-3 mt-1 file:border-none file:px-3 file:py-1 file:rounded-lg text-sm">
        {imgPreview ? (
          <img
            className={`w-full h-96 object-cover rounded-lg`}
            src={imgPreview}
            alt="preview"
          />
        ) : (
          <div className="text-center">No Image</div>
        )}
      </div>

      <div className="font-medium">Content</div>
      <Editor
        initialValue={content}
        apiKey="kmzhpan6svv2moc42xwwd0ywfu653q2wmfbbujjo0rzrl0mz"
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={initEditor}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={onSubmit}
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
        >
          Post
        </button>
      </div>
    </div>
  );
};

export default AddArticlePage;
