import React, { useCallback, useEffect, useState } from "react";
import { CompCardContainer } from "components";
import { icons } from "assets/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_URL_services } from "constants";
import { deleteData, getData } from "actions";
import { servicesReducers } from "redux/servicesSlice";
import { SyncLoader } from "react-spinners";
import moment from "moment";

const ServicePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getServicesResult,
    getServicesLoading,
    getServicesError,
    addServicesResult,
    deleteServicesResult,
  } = useSelector((state) => state.services);

  const get = useCallback(
    async (params) => {
      getData(
        API_URL_services,
        params,
        { dispatch, redux: servicesReducers },
        "GET_SERVICES"
      );
    },
    [dispatch]
  );

  const onEdit = (item) => {
    navigate(`/service/form`, {
      state: {
        item,
      },
    });
  };

  const onDelete = (pk) => {
    deleteData(
      API_URL_services + pk,
      { dispatch, redux: servicesReducers },
      "DELETE_SERVICES"
    );
  };

  const onSearch = (value) => {
    const params = `?limit=${""}&offset=${""}&search=${value}`;
    get(params);
  };

  const fetchData = useCallback(() => {
    const params = `?limit=${""}&offset=${""}&search=${""}`;
    get(params);
  }, [get]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (addServicesResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addServicesResult]);
  useEffect(() => {
    if (deleteServicesResult) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteServicesResult]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-lg md:text-3xl font-bold transition-all">
          Service
        </h1>
        <button
          className="text-xs md:text-sm whitespace-nowrap font-medium px-4 py-2 bg-primary-base hover:bg-primary-baseHover active:bg-primary-baseActive text-white rounded-lg shadow hover:shadow-lg transition-all"
          onClick={() => navigate("/service/form")}
        >
          Add Service
        </button>
      </div>
      <br />

      {/* Content */}
      <CompCardContainer>
        <div className="w-full flex text-gray-600">
          <div className="p-1 text-lg mr-3">{icons.fisearch}</div>
          <input
            type="text"
            className="w-full"
            placeholder="Search Service"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </CompCardContainer>
      <br />
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Loading */}
        {getServicesLoading && (
          <div className="w-full  h-56 col-span-full flex justify-center items-center">
            <SyncLoader
              loading={getServicesLoading}
              color="#1F2937"
              size={20}
            />
          </div>
        )}

        {/* Error */}
        {getServicesError && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm text-red-500 font-medium">
            {getServicesError}
          </div>
        )}

        {/* Result = 0 */}
        {getServicesResult && getServicesResult.count === 0 && (
          <div className="w-full h-56 col-span-full flex justify-center items-center text-sm font-medium">
            No Data
          </div>
        )}

        {getServicesResult &&
          getServicesResult.results.map((service, serviceIdx) => (
            <div
              key={serviceIdx}
              className="bg-white group relative w-full h-56 rounded-lg shadow p-4 flex flex-col justify-between hover:shadow-lg transition-all"
            >
              <div>
                <div className="text-lg font-medium mb-1">{service.name}</div>
                <div className="line-clamp-3 my-2 text-xs">
                  {service.description}
                </div>
                <ul className="text-xs">
                  {service.features.slice(0, 3).map((item, itemIdx) => (
                    <li key={itemIdx} className="pb-1 line-clamp-1">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-between">
                <div className="text-[10px]">
                  {moment(service.updated_at).format("D MMMM YYYY, h:mm")}
                </div>
                <div className="text-[10px]">{service.status}</div>
              </div>
              <div className="absolute flex gap-2 right-4 opacity-0 group-hover:opacity-100 transition-all">
                <button
                  className="text-blue-600"
                  onClick={() => onEdit(service)}
                >
                  {icons.fiedit}
                </button>
                <button
                  className="text-red-600"
                  onClick={() => onDelete(service.id)}
                >
                  {icons.rideletebin6line}
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServicePage;
