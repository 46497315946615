import { configureStore } from "@reduxjs/toolkit";
import articlesSlice from "./articlesSlice";
import partnershipsSlice from "./partnershipsSlice";
import pricelistsSlice from "./pricelistsSlice";
import servicesSlice from "./servicesSlice";
import bannersSlice from "./bannersSlice";

export default configureStore({
  reducer: {
    pricelists: pricelistsSlice,
    articles: articlesSlice,
    partnerships: partnershipsSlice,
    services: servicesSlice,
    banners: bannersSlice,
  },
});
